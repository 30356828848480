:root {
  --black900: #454545;
  --pink: #ff105e;
  --yellow: #fffb01;
  --violet: #88206d;
  --violetopacity: rgba(136, 32, 109, 0.5);
  --lightviolet: #cb5dae;
  --grey50: #fafafa;
  --grey100: #e5e5e5;
  --lightpink: #fdeffd;
  --lightgrey: #a9a9a920;
  --green: #0dfd14;
  --chatgreen: #e9ffc5;
  --gradneram: linear-gradient(
    111.37738709038058deg,
    #2b2d4e 1.557291666666667%,
    #e1148b 101.34895833333333%
  );
  --gradyellowgreen: linear-gradient(45deg, #f4d03f, #16a085);
  --gradblueviolet: linear-gradient(45deg, #21d4fd, #b721ff);
  --gradpinkred: linear-gradient(45deg, #f0f, #e90600);
  --gradyelloworange: linear-gradient(to right, #ffce31, #ff3d00);
  --gradredorange: linear-gradient(87.75deg, #ff1414 -56.47%, #ff9314 92.94%);
  /* GREY */
  --grey50: #fafafa;
  --grey100: #f5f5f5;
  --grey200: #eeeeee;
  --grey300: #e0e0e0;
  --grey400: #bdbdbd;
  --grey500: #9e9e9e;
  --grey600: #757575;
  --grey700: #616161;
  --grey800: #424242;
  --grey900: #212121;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
